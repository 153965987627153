import {
  getCustomersToReview,
  getCustomerWinesToReview,
  skipReviewCycleForContactsBatch
} from '@/api/reviews/reviews'
import { updateContact } from '@/api/contacts/contacts'
import { batchUpdateContactWinePromotions } from '@/api/reviews/contactwinepromotions'
import { merge, map } from 'lodash'
import Vue from 'vue'
import moment from 'moment'

export const state = () => ({
  loading: false,
  saving: false,
  savingContactId: 0,
  wineListLoading: false,
  list: [],
  wineList: []
})

export const getters = {}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSavingContactId(state, contactId) {
    if (contactId) {
      state.saving = true
      state.savingContactId = contactId
    } else {
      state.saving = false
      state.savingContactId = null
    }
  },
  setWineListLoading(state, loading) {
    state.wineListLoading = loading === true
  },
  setList(state, list) {
    state.list = list
  },
  setWineList(state, list) {
    state.wineList = list
  },
  updateCustomerById(state, contact) {
    const idx = state.list.findIndex(p => p.ContactID === contact.ContactID)
    if (idx > -1) {
      const merged = merge(state.list[idx], contact)
      Vue.set(state.list, idx, merged)
    }
  },
  removeCustomerById(state, contact) {
    const idx = state.list.findIndex(p => p.ContactID === contact.ContactID)
    if (idx > -1) {
      Vue.delete(state.list, idx)
    }
  },
  updateWineListPromotionOption(
    state,
    { WineCardID, ContactID, PromotionOption }
  ) {
    const idx = state.wineList.findIndex(
      p => p.ContactID === ContactID && p.WineCardID === WineCardID
    )
    if (idx > -1) {
      const merged = merge(state.wineList[idx], {
        WineCardID,
        ContactID,
        PromotionOption: parseInt(PromotionOption)
      })
      Vue.set(state.wineList, idx, merged)
    }
  }
}

export const actions = {
  async loadList({ commit }, args) {
    commit('setLoading', true)
    try {
      const response = await getCustomersToReview(args)
      if (response) {
        commit('setList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async loadWineList({ commit }, args) {
    commit('setWineListLoading', true)
    try {
      const response = await getCustomerWinesToReview(args)
      if (response) {
        commit('setWineList', response)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setWineListLoading', false)
    }
  },
  async updateContact({ commit, dispatch }, contact) {
    commit('setSavingContactId', contact.ContactID)
    try {
      const newContact = await updateContact(contact)
      commit('updateCustomerById', newContact)
      return newContact
    } catch (e) {
      throw e
    } finally {
      commit('setSavingContactId', null)
      commit('setLoading', false)
    }
  },
  async skipReviewCycleForContact(
    { commit, dispatch },
    { ContactID, DoNotReview, WinePromotions }
  ) {
    commit('setSavingContactId', ContactID)
    try {
      await batchUpdateContactWinePromotions({
        ContactID,
        DoNotReview,
        WinePromotions
      })
      return true
    } catch (e) {
      throw e
    } finally {
      commit('setSavingContactId', null)
      commit('setLoading', false)
    }
  },
  async skipReviewCycleForLoadedCustomers({ commit, state }) {
    const now = moment().format('YYYY-MM-DD HH:mm:ss')
    const ids = map(state.list, 'ContactID')
    commit('setLoading', true)
    try {
      return await skipReviewCycleForContactsBatch({
        Contacts: ids,
        Date: now
      })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  }
}
