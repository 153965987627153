<template>
  <group-box>
    <template slot="header">
      Recent Liv-Ex Data
      <b-spinner v-if="loading" type="grow" small />
    </template>
    <template v-if="!hideIfEmpty">
      <b-row>
        <b-col class="col-12 col-xxl-6">
          <b-form-group
            label="Offer"
            label-for="offerCancelledClass"
            label-size="sm"
            label-cols-xxl="6"
            class="mb-0"
          >
            <b-form-input
              v-if="offer===''"
              id="offerCancelledClass"
              size="sm"
              disabled
              class="font-weight-bold"
            />
            <b-form-input
              v-else
              id="offerCancelledClass"
              :value="offer|round(2)|withCommas"
              size="sm"
              disabled
              class="font-weight-bold"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-xxl-6">
          <b-form-group
            label="Date"
            label-for="formattedOfferDate"
            label-size="sm"
            label-cols-xxl="6"
            class="mb-0"
          >
            <b-form-input
              id="formattedOfferDate"
              :value="formattedOfferDate"
              size="sm"
              disabled
              class="font-weight-bold"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col class="col-12 col-xxl-6">
          <b-form-group
            label="Bid"
            label-for="bidCancelledClass"
            label-size="sm"
            label-cols-xxl="6"
            class="mb-0"
          >
            <b-form-input
              v-if="bid === ''"
              id="bidCancelledClass"
              size="sm"
              disabled
              class="font-weight-bold"
            />
            <b-form-input
              v-else
              id="bidCancelledClass"
              :value="bid|round(2)|withCommas"
              size="sm"
              disabled
              class="font-weight-bold"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-xxl-6">
          <b-form-group
            label="Date"
            label-for="formattedBidDate"
            label-size="sm"
            label-cols-xxl="6"
            class="mb-0"
          >
            <b-form-input
              id="formattedBidDate"
              :value="formattedBidDate"
              size="sm"
              disabled
              class="font-weight-bold"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <p class="text-center" style="font-weight: bold; font-size: .8rem; line-height: .8rem; margin-bottom: 2px;">No Results Found</p>
    </template>
  </group-box>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  components: {
    GroupBox
  },
  props: {
    listPrice: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      offer: '',
      offerDate: '',
      offerCancelled: false,
      bid: '',
      bidDate: '',
      bidCancelled: false,
      hideIfEmpty: false
    }
  },
  computed: {
    ...mapState('wineCard/activeCard', {
      liveExTransactions: state => state.liveExTransactions,
      loading: state => state.liveExTransactionsLoading,
      error: state => state.liveExTransactionsError
    }),
    ...mapState('global', {
      livExHighBidPercent: state => state.HighLivExBidPercent,
      livExLowBidPercent: state => state.LowLivExBidPercent,
      livExHighOfferPercent: state => state.HighLivExOfferPercent,
      livExLowOfferPercent: state => state.LowLivExOfferPercent
    }),
    formattedOfferDate() {
      if (this.offerDate.length > 0) {
        return moment(this.offerDate, 'YYYY-MM-DD').format('DD-MMM-YYYY')
      }
      return ''
    },
    formattedBidDate() {
      if (this.bidDate.length > 0) {
        return moment(this.bidDate, 'YYYY-MM-DD').format('DD-MMM-YYYY')
      }
      return ''
    },
    bidOutsideRange() {
      if (this.bidCancelled) {
        return false
      }
      const upperLimit =
        this.livExHighBidPercent > 0
          ? (this.listPrice * this.livExHighBidPercent) / 100
          : this.listPrice
      const lowerLimit =
        this.livExLowBidPercent > 0
          ? (this.listPrice * this.livExLowBidPercent) / 100
          : this.listPrice
      const offer = parseFloat(this.bid)
      return offer > upperLimit || offer < lowerLimit
    },
    offerOutsideRange() {
      if (this.bidCancelled) {
        return false
      }
      const upperLimit =
        this.livExHighOfferPercent > 0
          ? (this.listPrice * this.livExHighOfferPercent) / 100
          : this.listPrice
      const lowerLimit =
        this.livExLowOfferPercent > 0
          ? (this.listPrice * this.livExLowOfferPercent) / 100
          : this.listPrice
      const offer = parseFloat(this.offer)
      return offer > upperLimit || offer < lowerLimit
    },
    bidCancelledClass() {
      return {
        gray: this.bidCancelled,
        red: this.bidOutsideRange
      }
    },
    offerCancelledClass() {
      return {
        gray: this.offerCancelled,
        red: this.offerOutsideRange
      }
    }
  },
  watch: {
    liveExTransactions(transactions) {
      this.reset()
      for (let i = 0; i < transactions.length; i++) {
        const transaction = transactions[i]
        if (
          transaction.TransactionType !== '' &&
          transaction.Price > 0 &&
          transaction.CaseSize > 0 &&
          transaction.CaseUnits > 0
        ) {
          const adjustedPrice =
            (transaction.Price * transaction.CaseSize) / transaction.CaseUnits
          if (transaction.TransactionType === 'BID' && this.bid === '') {
            this.bid = adjustedPrice
            this.bidDate = transaction.TransactionDate
            this.bidCancelled = transaction.IsCancelled
          }
          if (transaction.TransactionType === 'OFFER' && this.offer === '') {
            this.offer = adjustedPrice
            this.offerDate = transaction.TransactionDate
            this.offerCancelled = transaction.IsCancelled
          }
        }
        if (this.bid !== '' && this.offer !== '') {
          i = transactions.length
        }
      }
    },
    loading(current, previous) {
      if (previous && !current) {
        this.hideIfEmpty = this.bid === '' && this.offer === ''
      }
    }
  },
  mounted() {
    this.$emit('get-liv-ex')
  },
  methods: {
    reset() {
      this.offer = ''
      this.offerDate = ''
      this.bid = ''
      this.bidDate = ''
      this.offerCancelled = false
      this.bidCancelled = false
    }
  }
}
</script>
