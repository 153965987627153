<template>
  <vue-custom-scrollbar
    class="virtual-scroll__content"
    :style="containerStyle"
    :settings="resultsSettings"
    @ps-scroll-y="handleScrollEvent"
  >
    <div :style="getOverallStyle">
      <div
        v-for="(pageItems,idx) in chunkedResults"
        :key="idx"
      >
        <div :id="'page'+idx" ref="page" :style="getPageStyle(idx)">
          <template v-if="hideOutOfViewport === false || isPageOutOfViewPort(idx) === false">
            <slot name="content" :items="pageItems" :page="idx" :updated="handleUpdated" />
          </template>
        </div>
      </div>
    </div>
    <slot v-if="loading || (cachedLoading && showLoading)" name="loading">
      <div class="p-10 text-center">
        {{ loadingText }}
      </div>
    </slot>
  </vue-custom-scrollbar>
</template>

<script>
import { chunk, filter, difference, each, throttle } from 'lodash'
import vueCustomScrollbar from '~/components/Base/VueCustomScrollbar'
export default {
  components: {
    vueCustomScrollbar
  },
  props: {
    hideOutOfViewport: {
      type: Boolean,
      required: false,
      default: true
    },
    items: {
      type: Array,
      required: false,
      default: null
    },
    getter: {
      type: String,
      required: false,
      default: null
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    nextLoadRequest: {
      type: Number,
      required: false,
      default: 80
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Loading Please Wait'
    },
    estimateTotalScrollHeight: {
      type: Boolean,
      required: false,
      default: false
    },
    throttleScroll: {
      type: Number,
      required: false,
      default: 100
    },
    throttleOptions: {
      type: Object,
      required: false,
      default() {
        return {
          trailing: true
        }
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: 'calc(100% - 50px)'
    },
    waitForUpdated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      pageRequested: 1,
      previousScrollHeight: 0,
      cachedInitialHeight: 0,
      scrollTop: 0,
      scrollBottom: 0,
      inViewport: [],
      outOfViewPort: {},
      cachedLoading: false,
      overallTotalHeight: 'auto',
      pcntScrolled: 0,
      throttledScrollEvent: null,
      resultsSettings: {
        tagname: 'div',
        suppressScrollX: true
      }
    }
  },
  computed: {
    containerStyle() {
      return {
        height: this.height
      }
    },
    itemsOrGetter() {
      if (this.items) {
        return this.items
      }
      return this.$store.getters[this.getter]
    },
    chunkedResults() {
      return chunk(this.itemsOrGetter, this.perPage)
    },
    gotPages() {
      return Math.ceil(this.itemsOrGetter.length / this.perPage)
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    lastPageInViewIndex() {
      const pageId = this.inViewport[this.inViewport.length - 1].getAttribute(
        'id'
      )
      return parseInt(pageId.substring(4, pageId.length))
    },
    getOverallStyle() {
      if (this.hideOutOfViewport) {
        return {
          'min-height': this.overallTotalHeight
        }
      }
      return ''
    }
  },
  watch: {
    itemsOrGetter() {
      this.handleUpdated()
    },
    showLoading(newValue, oldValue) {
      // console.log('showLoading changed from', oldValue, 'to', newValue)
    },
    loading(newValue, oldValue) {
      // console.log('loading changed from', oldValue, 'to', newValue)
    },
    cachedLoading(newValue, oldValue) {
      // console.log('cachedLoading changed from', oldValue, 'to', newValue)
    }
  },
  created() {
    this.throttledScrollEvent = throttle(
      this._throttledScrollEvent,
      this.throttleScroll
    )
  },
  updated() {
    if (this.loading === false) {
      this.cachedLoading = false
    }
  },
  mounted() {
    this.handleUpdated()
  },
  methods: {
    handleUpdated() {
      if (this.loading === false) {
        this.cachedLoading = false
      }
      // this.handleEstimateTotalHeight()
      this.handleInViewPort()
    },
    isPageOutOfViewPort(pageId) {
      return this.outOfViewPort[pageId] !== undefined
    },
    getPageStyle(pageId) {
      if (this.isPageOutOfViewPort(pageId)) {
        return {
          height: this.outOfViewPort[pageId] + 'px'
        }
      } else {
        return {
          height: 'auto'
        }
      }
    },
    _throttledScrollEvent() {
      this.scrollTop = this.$el.scrollTop
      this.scrollBottom = this.scrollTop + this.$el.clientHeight
      this.handleInViewPort()
      this.handleEstimateTotalHeight()
      if (this.scrollTop > this.previousScrollHeight) {
        this.handleScrollingDown()
      }
      this.previousScrollHeight = this.scrollTop
    },
    handleScrollEvent(event) {
      this.throttledScrollEvent()
    },
    handleScrollingDown() {
      const firstPage = this.inViewport[this.inViewport.length - 1]
      if (firstPage === undefined) {
        this.cachedLoading = true
        return
      }
      const pageTop = firstPage.offsetTop
      const scrollOffset = this.scrollBottom - pageTop
      this.pcntScrolled = (scrollOffset / firstPage.offsetHeight) * 100
      this.handleRequests()
    },
    handleInViewPort() {
      const previousViewPort = this.inViewport
      this.inViewport = filter(this.$refs.page, el => {
        const elBottom = el.offsetTop + el.offsetHeight
        return this.scrollBottom > el.offsetTop && elBottom > this.scrollTop
      })
      const movedOutOfViewPort = difference(previousViewPort, this.inViewport)
      const movedIntoViewPort = difference(this.inViewport, previousViewPort)
      each(movedOutOfViewPort, page => {
        let pageId = page.getAttribute('id')
        pageId = pageId.substring(4, pageId.length)
        this.$set(this.outOfViewPort, parseInt(pageId), page.clientHeight)
      })
      each(movedIntoViewPort, page => {
        let pageId = page.getAttribute('id')
        pageId = pageId.substring(4, pageId.length)
        this.$delete(this.outOfViewPort, parseInt(pageId))
      })
    },
    handleRequests() {
      if (
        this.pcntScrolled >= this.nextLoadRequest &&
        this.gotPages <= this.lastPageInViewIndex + 1 &&
        this.gotPages < this.totalPages &&
        this.cachedLoading === false
      ) {
        this.cachedLoading = true
        this.$emit('request-page', this.gotPages + 1)
      }
    },
    handleEstimateTotalHeight() {
      if (this.estimateTotalScrollHeight && this.$refs.page) {
        if (this.cachedLoading && this.pcntScrolled >= 100) {
          this.overallTotalHeight = 'auto'
        } else {
          const totalGotHeight = this.$refs.page.reduce((c, p) => {
            return p.offsetHeight + c
          }, 0)
          const avg = Math.ceil(totalGotHeight / this.$refs.page.length)
          const totalHeight = avg * this.totalPages
          this.overallTotalHeight = totalHeight + 'px'
        }
      }
    }
  }
}
</script>
