import {
  getStoragePricingGroups,
  addStoragePricingGroup,
  updateStoragePricingGroup,
  removeStoragePricingGroup
} from '~/api/storage/storagePricingGroup'
import Vue from 'vue'

export const state = () => ({
  storagePricingGroups: {},
  selectedStoragePricingGroupIndex: null
})

export const getters = {
  getStoragePricingGroups: state => {
    return state.storagePricingGroups
  },
  getSelectedStoragePricingGroup: state => {
    if (state.selectedStoragePricingGroupIndex !== null) {
      return state.storagePricingGroups[state.selectedStoragePricingGroupIndex]
    }
  }
}

export const mutations = {
  setStoragePricingGroups(state, data) {
    Vue.set(state, 'storagePricingGroups', data)
  },
  setSelectedStoragePricingGroup(state, SPGID) {
    state.selectedStoragePricingGroupIndex = SPGID
  }
}

export const actions = {
  async getStoragePricingGroups({ commit }) {
    const result = await getStoragePricingGroups()
    if (result) {
      commit(
        'setStoragePricingGroups',
        result.storagePricingGroupPaginatedResults
      )
    }
    return result
  },
  async updateStoragePricingGroup({ commit }, payload) {
    // commit('setLoadingInventories', true)
    try {
      const result = await updateStoragePricingGroup(payload)
      if (result) {
        console.log('result', result)
        // commit('updateStoragePricingGroup', result)
      }
      return result
    } catch (e) {
      throw e
    }
  },
  async addStoragePricingGroup({ commit }, payload) {
    try {
      const result = await addStoragePricingGroup(payload)
      if (result) {
        console.log('result', result)
        // commit('addStoragePricingGroup', result)
      }
      return result
    } catch (e) {
      throw e
    }
  },
  async removeStoragePricingGroup({ commit }, payload) {
    try {
      const result = await removeStoragePricingGroup(payload)
      if (result) {
        console.log('result', result)
        // commit('addStoragePricingGroup', result)
      }
      return result
    } catch (e) {
      throw e
    }
  }
}
