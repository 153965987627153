import { clearAllPromotions, updateBatchPromotions } from '@/api/reserves/wines'
import { getWineCardSearch } from '@/api/reserves/wineCardSearch'
import { state, getters, mutations } from '../wineBrowser/wineCards'

export const actions = {
  async performSearch({ commit, state }, { searchParameters }) {
    try {
      commit('setLoading', true)
      commit('setSearchState', 'reset')
      searchParameters.orderBy = state.orderBy
      searchParameters.orderByDirection = state.orderByDirection
      const apiMethod = getWineCardSearch
      const response = await apiMethod(
        state.page,
        state.perPage,
        searchParameters
      )
      commit('setSearchState', 'searched')
      commit('setPageOffset', state.page)
      commit('setResults', response.wineBrowserResults)
      commit('setTotal', response.total)
      commit('setLoadedPage', state.page)
      commit('setLoading', false)
    } catch (e) {
      commit('setSearchState', 'reset')
      commit('setPageOffset', 1)
      commit('setResults', [])
      commit('setTotal', 0)
      commit('setLoadedPage', 1)
      commit('setLoading', false)
      console.log('performSearch exception', e)
      throw e
    }
  },
  async changePage({ commit, state }, { page, searchParameters }) {
    if (state.loadedPage.indexOf(page) >= 0) {
      commit('setPage', page)
      return
    }
    commit('setLoading', true)
    searchParameters.orderBy = state.orderBy
    searchParameters.orderByDirection = state.orderByDirection
    const apiMethod = getWineCardSearch
    const response = await apiMethod(page, state.perPage, searchParameters)
    commit('setPageOffset', page)
    commit('appendResults', response.wineBrowserResults)
    commit('setPage', page)
    commit('setLoadedPage', page)
    commit('setLoading', false)
  },
  changeOrder({ commit, state, dispatch }, { key, searchParameters }) {
    const searchKey = state.orderKeyMap[key] || null

    if (searchKey) {
      const doDesc =
        state.orderBy === searchKey && state.orderByDirection === 'ASC'
      commit('resetSelection')
      commit('setOrderBy', searchKey)
      commit('setOrderByDirection', doDesc ? 'DESC' : 'ASC')
      // console.log('changeOrder', searchParameters)
      dispatch('performSearch', { searchParameters })
    } else {
      console.error('No Key Map For ' + key)
    }
  },
  updateByWineCard({ state, commit }, toMergeObject) {
    const wineCardIdx = state.indexedId[toMergeObject.id]
    if (wineCardIdx !== undefined) {
      const tmpCard = JSON.parse(JSON.stringify(state.results[wineCardIdx]))
      commit('updateById', {
        id: toMergeObject.id,
        toMergeObject
      })
      return tmpCard
    }
  },
  async clearAllPromotions() {
    try {
      return await clearAllPromotions()
    } catch (e) {
      throw e
    }
  },
  async applyPromotions({ commit, state }) {
    commit('setLoading', true)
    const WineCards = Object.keys(state.selectedForPromotion).map(k => {
      return {
        WineCardID: parseInt(k),
        PromotionDate: state.selectedForPromotion[k]
      }
    })
    const updatedSet = await updateBatchPromotions({ WineCards })
    updatedSet.forEach(result => {
      commit('replaceResultById', {
        id: result.id,
        result
      })
    })
    commit('setLoading', false)
    commit('resetPromotions')
  },
  async refreshResults({ commit, state }, searchParameters) {
    let currentPage = state.page
    commit('setLoading', true)
    searchParameters.orderBy = state.orderBy
    searchParameters.orderByDirection = state.orderByDirection
    const apiMethod = getWineCardSearch
    const response = await apiMethod(
      currentPage,
      state.perPage,
      searchParameters
    )
    // console.log('refreshResults', response)
    commit('replaceResultsForPage', {
      page: currentPage,
      results: response.wineBrowserResults
    })
    currentPage--
    const otherPageRequests = []
    for (let i = currentPage; i > 0; i--) {
      otherPageRequests.push(
        (page => {
          return new Promise(async (resolve, reject) => {
            try {
              const response = await apiMethod(
                page,
                state.perPage,
                searchParameters
              )
              // console.log('replaceResultsForPage', response)
              commit('replaceResultsForPage', {
                page: page,
                results: response.wineBrowserResults
              })
              resolve()
            } catch (e) {
              reject(e)
            }
          })
        })(i)
      )
    }
    await Promise.all(otherPageRequests)

    commit('setLoading', false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
