<template>
  <nav class="wv-nav">
    <b-collapse id="collapse-navbar">
      <div class="wv-navbar__container">
        <ul class="wv-navbar__list wv-navbar__list--primary">
          <li v-for="(item, index) in mainItems" :key="index" :class="{ 'active': isActive(item) }" class="wv-nav__link">
            <nuxt-link v-d-toggle="`snc-${index}`" :to="item.to" class="p-1" :class="item.class">
              {{ item.title }}
            </nuxt-link>
          </li>
        </ul>
        <ul class="wv-navbar__list wv-navbar__list--secondary">
          <b-nav-item-dropdown class="wv-nav__link">
            <template slot="button-content">
              <img v-b-tooltip.left.hover :title="user.name + ' ('+user.email+')'" :src="'https://api.dicebear.com/7.x/initials/svg?seed=' + user.name + '&size=21&radius=50&backgroundType=gradientLinear&backgroundColor=1f35f4'">
            </template>
            <b-dropdown-item @click="logout">
              Sign Out
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </ul>
      </div>
    </b-collapse>
    <a v-b-toggle.collapse-navbar href="#" class="collapse-button">
      <span class="material-icons">
        menu
      </span>
    </a>
  </nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    mainItems: {
      type: Array,
      default() {
        return []
      }
    },
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    menuAction(item) {
      if (item.action) {
        item.action()
      }
    },
    logout() {
      this.$store.dispatch('user/removeToken')
      window.location.reload()
    },
    isActive(item) {
      if (!this.$route) {
        return false
      }

      const routeFirst = this.$route.name.split('-')[0]
      const itemFirst = item.to.name.split('-')[0]

      const routeSecond = this.$route.name.split('-')[1]
      const itemSecond = item.to.name.split('-')[1]

      if (routeFirst === 'reserves' && itemFirst === 'reserves') {
        return routeSecond === itemSecond
      }

      if (routeFirst === 'wine' && itemFirst === 'wine') {
        return routeSecond === itemSecond
      }
      return itemFirst === routeFirst
    }
  }
}
</script>
